import React from "react";
import "./Box.scss";
const Box = (props: any) => {
  const { data, type, children } = props;
  return (
    <>
      {children ? (
        <div className={`mod ${type ? `mod-${type}` : ''}`}>{children}</div>
      ) : (
        <div
          className={`mod ${type ? `mod-${type}` : ''}`}
          dangerouslySetInnerHTML={{ __html: data }}
        ></div>
      )}
    </>
  );
};
export default Box;
