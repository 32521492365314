import React, { useState, useEffect } from "react";
import "./App.scss";
import API from "../api/api";
import { useHistory } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import CardList from "../components/CardList";
import Box from "../components/Box";
function Honors() {
  const history = useHistory();
  const [data, setData] = useState<any>();
  const getZhaopinList = () => {
    API.Api('zhaopin').then((res: any) => {
      if (res.code === 0) {
        setData(res.data);
      } else {
        history.push("/");
      }
    });
  };
  useEffect(() => {
    getZhaopinList();
    // eslint-disable-next-line
  }, []);
  return (
    <div className='main'>
      <PageTitle title={data?.title} subtitle={data?.subTitle} />
      <Box type={"border"}>
        <CardList data={data?.data} className={"zhaopin-list"} />
      </Box>
    </div>
  );
}

export default Honors;
