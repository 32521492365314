import React, { useState, useEffect } from "react";
import API from "../../api/api";
import "./Banner.scss";
const Banner = (props: any) => {
  const [data, setData] = useState<any>([]);
  const getBannerInfos = () => {
    API.Api('banner').then((res: any) => {
      if (res.code === 0) {
        setData(res.data.data);
      }
    });
  };
  useEffect(() => {
    getBannerInfos();
  }, []);
  return (
    <div
      className='b-slider-box'
      style={{ backgroundImage: `url(${data[0]?.imgSrc})`,height:`${data[0]?.height}px` }}
    ></div>
  );
};
export default Banner;
