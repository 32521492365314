import React from "react";
import "./PageTitle.scss";
const PageTitle = (props: any) => {
  const { title, subtitle } = props;

  return (
    <h3 className='title'>
      <span
        className='main-title'
        dangerouslySetInnerHTML={{ __html: title }}
      ></span>
      <small
        className='sub-title'
        dangerouslySetInnerHTML={{ __html: subtitle }}
      ></small>
    </h3>
  );
};
export default PageTitle;
