import React, { useEffect, useState } from "react";
import './Timeline.scss'
const Timeline = (props: any) => {
  const { data } = props;
  const [count, setCount] = useState<number>(15);
  let itemLen = data?.length;
  const [items, setItems] = useState<any>([]);
  const [active,setActive]=useState(0)
  const getMoreData = () => {
    setItems(data?.slice(0, count));
    setCount(count + 10)
  };
  const MoreBtn = () => {
    return (
      <>
        {count < itemLen && (
          <span
            className='timeline-more'
            onClick={() => {
              getMoreData();
            }}
          >
            显示更多
          </span>
        )}
      </>
    );
  };

  useEffect(() => {
    setItems(data?.slice(0, count));
  }, [data,count]);
  return (
    <div className='timeline-wrap'>
      <div className='timeline-box'>
        <ul className='timeline-list'>
          {items &&
            items.map((item: any, index: any) => {
              return (
                <li key={index}
                 className={`timeline-item ${
                  active === index ? 'timeline-on' : ''
                }`}
                onMouseEnter={() => {
                  setActive(index);
                }}
                 >
                  <h5 className='timeline-time'>
                    {item.aboutTime}
                  </h5>
                  <p
                    className='timeline-para'
                    dangerouslySetInnerHTML={{ __html: item.aboutContent }}
                  ></p>
                </li>
              );
            })}
        </ul>
      </div>
      <div className='btn-gtoups'>
        <MoreBtn />
      </div>
    </div>
  );
};
export default Timeline;
