import React, { useState, useEffect } from "react";
import "./App.scss";
import API from "../api/api";
import { useHistory } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import Timeline from "../components/Timeline";
function Memorabilia() {
  const history = useHistory();
  const [data, setData] = useState<any>();
  const getMemorabiliaList = () => {
    API.Api('memorabilia').then((res: any) => {
      if (res.code === 0) {
        setData(res.data);
      } else {
        history.push("/");
      }
    });
  };
  useEffect(() => {
    getMemorabiliaList();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className='main'>
        <PageTitle title={data?.title} subtitle={data?.subTitle} />
        <Timeline data={data?.data} />
      </div>
    </>
  );
}

export default Memorabilia;
