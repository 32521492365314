
import { get } from "./requestMethods";

class API {
  static async Api(params?:any){
    return await get({
      url:`?id=about_${params}`
    });
  }
}

export default API;
