import { http } from "./request";
import querystring from "qs";

export const post = (options:any) => {
  const { dataType } = options;
  return http({
    headers: {
      "Content-Type": dataType === 'form' ? "application/x-www-form-urlencoded" : "application/json;charset=UTF-8",
    },
    transformRequest: [
      (data) => {
        // return querystring.stringify(data, { allowDots: true });
        if (dataType === "form") {
          return querystring.stringify(data, {arrayFormat: "repeat"});
        } else return JSON.stringify(data);
        
      },
    ],
    ...options,
    method: "post"
  });
};

export const get = (options:any) => {
  return http({
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    ...options,
    method: "get"
  });
};
