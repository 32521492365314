import React, { useState, useEffect } from "react";
import "./App.scss";
import API from "../api/api";
import { useHistory } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import Box from "../components/Box";
function Company() {
  const history = useHistory();
  const [data, setData] = useState<any>();
  const getComanyInfos = () => {
    API.Api('company').then((res: any) => {
      if (res.code === 0) {
        setData({ ...res.data, data: res.data.data.split("<hr/>") });
      } else {
        history.push("/");
      }
    });
  };
  useEffect(() => {
    getComanyInfos();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className='main'>
        <PageTitle title={data?.title} subtitle={data?.subTitle} />
        {data?.data.map((item: any, index: number) => {
          return <Box key={"company" + index} data={item} type={"border"} />;
        })}
      </div>
    </>
  );
}

export default Company;
