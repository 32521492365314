import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./CardList.scss";
const CardList = (props: any, ref: any) => {
  const { data, onClick, className } = props;
  const [state, setState] = useState<any>(0);
  const handleClick = (index: number) => {
    setState(index);
    onClick && onClick();
  };
  useImperativeHandle(ref, () => ({
    state,
    setState,
  }));
  return (
    <ul className={`card-list ${className}`}>
      {data &&
        data?.map((item: any, index: any) => {
          return (
            <li
              className={`card-item ${
                item.cardMainTitle ? "card-text" : "card-img"
              }`}
              key={index}
              onClick={() => {
                handleClick(index);
              }}
              ref={ref}
            >
              {item.cardEngTitle && (
                <div
                  className='fore1'
                  dangerouslySetInnerHTML={{ __html: item.cardEngTitle }}
                ></div>
              )}
              {item.cardMainTitle && (
                <div
                  className='fore2'
                  dangerouslySetInnerHTML={{ __html: item.cardMainTitle }}
                ></div>
              )}
              {item.cardSubTitle && (
                <div
                  className='fore3'
                  dangerouslySetInnerHTML={{ __html: item.cardSubTitle }}
                ></div>
              )}
              {item.cardButtonTxt && item.cardUrl && (
                <a href={item.cardUrl} className='link-view'>
                  {item.cardButtonTxt}
                </a>
              )}
              {item.cardImg && <img src={item.cardImg} alt='' />}
            </li>
          );
        })}
    </ul>
  );
};
export default forwardRef(CardList);
