import React, { useState, useEffect, useRef } from "react";
import "./App.scss";
import { useHistory } from "react-router-dom";
import API from "../api/api";
import PageTitle from "../components/PageTitle";
import CardList from "../components/CardList";
import Box from "../components/Box";
function Compliance() {
  const history = useHistory();
  const [data, setData] = useState<any>();
  const complianceRef: any = useRef();
  const getComplianceInfos = () => {
    API.Api('compliance').then((res: any) => {
      if (res.code === 0) {
        setData(res.data);
      }
    });
  };
  const getUrl = () => {
    setTimeout(() => {
      history.push(
        "compliancechild?name=" +
          data?.data[complianceRef.current.state].cardUrl
      );
    });
  };
  useEffect(() => {
    getComplianceInfos();
    // eslint-disable-next-line
  }, []);
  return (
    <div className='main'>
      <PageTitle title={data?.title} subtitle={data?.subTitle} />
      <Box type={"border"}>
        <CardList
          data={data?.data}
          className={"compliance-list"}
          onClick={getUrl}
          ref={complianceRef}
        />
      </Box>
    </div>
  );
}

export default Compliance;
