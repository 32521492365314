import React, { useState, useEffect, useMemo } from "react";
import API from "../../api/api";
import "./Menu.scss";
import { useHistory } from "react-router-dom";
const Menu = (props: any) => {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [state, setState] = useState("");
  const getMenuList = () => {
    API.Api('menu').then((res: any) => {
      if (res.code === 0) {
        setData(res.data.data);
      }
    });
  };
  const getUrl = (url: any, isUrl: boolean) => {
    if (isUrl) {
      window.open(url);
    } else {
      history.push("/" + url);
    }
  };
  const currentPathname = useMemo(()=>{
    return history.location.pathname.replace(/\//g, "");
  },[history])
  useEffect(() => {
    setState(currentPathname);
  }, [currentPathname]);
  useEffect(() => {
    getMenuList();
  }, []);
  return (
    <div className='l-menu-wp'>
      <ul className='l-menu-list'>
        {data.map((item: any) => {
          return (
            <React.Fragment key={item.menuUrl}>
              {item.isOpen && (
                <li
                  className={`l-menu-item ${
                    state === item.menuUrl ? "on" : ""
                  }`}
                  onClick={() => getUrl(item.menuUrl, item.isUrl)}
                >
                  {item.menuName}
                </li>
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};
export default Menu;
