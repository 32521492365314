import React, { useState, useEffect } from "react";
import "./App.scss";
import API from "../api/api";
import { useHistory } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import Box from "../components/Box";
function Contact() {
  const history = useHistory();
  const [data, setData] = useState<any>();
  const getContactList = () => {
    API.Api('contact').then((res: any) => {
      if (res.code === 0) {
        setData(res.data);
      }else {
        history.push("/");
      }
    });
  };
  useEffect(() => {
    getContactList();
    // eslint-disable-next-line
  }, []);
  return (
    <div className='main' id='contact'>
      <PageTitle title={data?.title} subtitle={data?.subTitle} />
      <Box type={"border"} data={data?.data} />
    </div>
  );
}

export default Contact;
