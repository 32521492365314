import React, { useState, useEffect } from "react";
import "./App.scss";
import API from "../api/api";
import { useHistory } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import Box from "../components/Box";
import Dialog from "../components/Dialog";
import { getCookie, setCookie } from "@jmfe/jd-jssdk";
function Privacy() {
  const history = useHistory();
  const [data, setData] = useState<any>();
  const [close, setClose] = useState<boolean>(true);
  const getPrivacyInfos = () => {
    API.Api('privacy').then((res: any) => {
      if (res.code === 0) {
        const oldCookie = getCookie("data-cookie");
        setData({ ...res.data, data: res.data.content.split("<hr/>") });
        if (oldCookie === res.data?.dialogMark) {
          setClose(false);
        } else {
          setClose(true);
        }
      } else {
        history.push("/");
      }
    });
  };
  const handleCookies = () => {
    let times = new Date(new Date().getTime() + 24 * 3600 * 1000 * 365);
    setCookie("data-cookie", data?.dialogMark, { expires: times });
  };
  useEffect(() => {
    getPrivacyInfos();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className='main' id='parivacy'>
        <PageTitle title={data?.title} subtitle={data?.subTitle} />
        <Box type={"border"}>
          <ul className='date-wrap'>
            <li className='fore1'>版本更新日期：{data?.date}</li>
            <li className='fore2'>版本生效日期：{data?.dateEff}</li>
          </ul>
          <div
            className='mod-bd'
            dangerouslySetInnerHTML={{ __html: data?.tips }}
          ></div>
          <div
            className='mod-bd'
            dangerouslySetInnerHTML={{ __html: data?.introduction }}
          ></div>
          {data?.data.map((item: any, index: number) => {
            return (
              <div
                className='mod-bd'
                id={`b-f${index + 1}`}
                key={index}
                dangerouslySetInnerHTML={{ __html: item }}
              ></div>
            );
          })}
        </Box>
      </div>
      {data?.dialogMark && (
        <Dialog
          close={close}
          onClick={() => handleCookies()}
          title={"《京东隐私政策》更新"}
        >
          <div
            className='privacy-content'
            dangerouslySetInnerHTML={{ __html: data?.dialog }}
          ></div>
          <div
            className='privacy-content'
            dangerouslySetInnerHTML={{ __html: data?.tips }}
          ></div>
        </Dialog>
      )}
    </>
  );
}

export default Privacy;
