import React, { useState, useEffect } from "react";
import "./App.scss";
import { useHistory } from "react-router-dom";
import { getUrlQuery } from "@jmfe/jd-jssdk";
import API from "../api/api";
import Box from "../components/Box";
function Compliance() {
  const history = useHistory();
  const [data, setData] = useState<any>();
  const getComplianceInfos = () => {
    const type = getUrlQuery("name");
    API.Api("compliance_" + type).then((res: any) => {
      if (res.code === 0) {
        document.title = res.data.title;
        setData(res.data);
      } else {
        history.push("/");
      }
    });
  };
  useEffect(() => {
    getComplianceInfos();
    // eslint-disable-next-line
  }, []);
  return (
    <div className='main' id='compliance'>
      <Box data={data?.data} type={"nopadding"} />
    </div>
  );
}

export default Compliance;
