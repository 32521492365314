import React, { useState, useEffect } from "react";
import "./App.scss";
import API from "../api/api";
import { useHistory } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import Box from "../components/Box";
function Founder() {
  const history = useHistory();
  const [data, setData] = useState<any>();
  const getFounderInfos = () => {
    API.Api('founder').then((res: any) => {
      if (res.code === 0) {
        setData(res.data);
      } else {
        history.push("/");
      }
    });
  };
  useEffect(() => {
    getFounderInfos();
    // eslint-disable-next-line
  }, []);
  return (
    <div className='main' id='founder'>
      <PageTitle title={data?.title} subtitle={data?.subTitle} />
      <Box data={data?.data} type={"border"} />
    </div>
  );
}

export default Founder;
