/**
 * http配置
 */
import axios from "axios";

// 超时时间
axios.defaults.timeout = 5 * 60 * 1000;

//开发、预发环境调用预发接口
axios.defaults.baseURL = "https://services.jd.com/neos/data";

// 请求携带cookie
axios.defaults.withCredentials = true;

// http请求拦截器，添加时间戳 防止缓存
const addRequestQuery = (config:any) => {
  if (config.method.toLowerCase() === "get") {
    const url = config.url;
    const t = new Date().getTime();
    config.url = `${url}${url.indexOf("?") === -1 ? "?" : "&"}t=${t}`;
  }
  config.headers['x-source-id'] = 0
  config.headers['x-source'] = 2
  return config;
};

axios.interceptors.request.use(
  (config) => {
    config = addRequestQuery(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 统一处理结果
axios.interceptors.response.use(
  (response) => {
    const data = response.data;
    switch (data.code) {
      case "200":
        return data;
      default:
        // Toast.show(data.msg);
        return data;
    }
  },
  (error) => {
    const res = error.response;
    if (res && res.config) {
      if (res.config.outErrorTip) {
        return Promise.reject(error);
      }
    }
  }
);

export { axios as http };
export default axios;
