import React from "react";
import { BrowserRouter as Router, Route, Switch,Redirect } from "react-router-dom";
import routes from "./routerPages";
import Menu from "../components/Menu";
import Banner from "../components/Banner";
const RouteWithSubRoutes = (route: any) => {
  return (
    <Route
      exact
      path={route.path}
      render={(props: any) => {
        document.title = route?.meta?.title ?? "关于京东";
        return (
          <>
            <Banner />
            <div className='wrap'>
              {route.sidebar ? <Menu /> : ""}
              <route.component
                {...props}
                {...route.params}
                routes={route.routes}
              />
            </div>
          </>
        );
      }}
    />
  );
};

const BasicRoute = () => (
  <Router>
    <Switch>
      {routes &&
        routes.map((route: any, i: any) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
         <Redirect to='/' />
    </Switch>
  </Router>
);

export default BasicRoute;
