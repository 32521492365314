import Company from "../pages/Company";
import Founder from "../pages/Founder";
import Privacy from "../pages/Privacy";
import Compliance from "../pages/Compliance";
import ComplianceChild from "../pages/ComplianceChild";
import Honors from "../pages/Honors";
import Memorabilia from "../pages/Memorabilia";
import Culture from "../pages/Culture";
import Contact from "../pages/Contact";
import Cooperate from "../pages/Cooperate";
import Zhaopin from "../pages/Zhaopin"
const routes: any = [
  {
    path: `/company`,
    name: "Company",
    exact: true,
    meta: {
      title: "企业简介",
    },
    sidebar: true,
    component: Company,
  },
  {
    path: `/founder`,
    name: "Founder",
    exact: true,
    sidebar: true,
    meta: {
      title: "创始人",
    },
    component: Founder,
  },
  {
    path: `/privacy`,
    name: "Privacy",
    exact: true,
    sidebar: true,
    meta: {
      title: "京东隐私政策",
    },
    component: Privacy,
  },
  {
    path: `/`,
    name: "Compliance",
    sidebar: true,
    exact: true,
    meta: {
      title: "内控合规",
    },
    component: Compliance,
  },
  {
    path: `/compliancechild`,
    name: "Compliance1",
    sidebar: false,
    exact: true,
    meta: {
      title: "",
    },
    component: ComplianceChild,
  },
  {
    path: `/honors`,
    name: "Honors",
    sidebar: true,
    exact: true,
    meta: {
      title: "企业荣誉",
    },
    component: Honors,
  },
  {
    path: `/memorabilia`,
    name: "Memorabilia",
    sidebar: true,
    exact: true,
    meta: {
      title: "大事记",
    },
    component: Memorabilia,
  },
  {
    path: `/culture`,
    name: "Culture",
    sidebar: true,
    exact: true,
    meta: {
      title: "企业文化",
    },
    component: Culture,
  },
  {
    path: `/contact`,
    name: "Contact",
    sidebar: false,
    exact: true,
    meta: {
      title: "联系我们",
    },
    component: Contact,
  },{
    path: `/cooperate`,
    name: "Cooperate",
    sidebar: false,
    exact: true,
    meta: {
      title: "对外合作",
    },
    component: Cooperate,
  },{
    path: `/zhaopin`,
    name: "Zhaopin",
    sidebar: true,
    exact: true,
    meta: {
      title: "人才招聘",
    },
    component: Zhaopin,
  }
];

export default routes;
