import React, { useEffect, useState } from "react";
import "./Dialog.scss";
import { createGlobalStyle } from "styled-components";
const Dialog = (props: any) => {
  const { children, close, title, onClick } = props;
  const [display, setDisplay] = useState<any>(true);
  const handleClose = () => {
    setDisplay(false);
    onClick && onClick();
  };
  const GlobalStyle = createGlobalStyle`
  body {
    overflow:${display ? "hidden" : ""}
  }
`;
  useEffect(() => {
    close ? setDisplay(true) : setDisplay(false);
  }, [close]);
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <div
        className='dialog-wrap'
        style={{ display: display ? "block" : "none" }}
      >
        <div className='dialog-close' onClick={() => handleClose()}>
          &times;
        </div>
        {title && <div className='dialog-title'>{title}</div>}
        <div className='dialog-content'>{children}</div>
        <div className='dialog-groups'>
          <button
            className='btn-sure'
            type='button'
            onClick={() => handleClose()}
          >
            我知道了
          </button>
        </div>
      </div>
      <div
        className='dialog-mask'
        style={{ display: display ? "block" : "none" }}
      ></div>
    </>
  );
};
export default Dialog;
